import React from 'react';
import { CustomFormProps } from '../DialogRenderer';

import './PrintBadgeUserControl.css';

import { BadgeCanvas } from './BadgeCanvas';
import { FiveMindsHeader } from '../EinlassStartDialog/FiveMindsHeader';
import { PrintBadgeUserControlState } from './PrintBadgeUserControlState';

export class PrintBadgeUserControl extends React.Component<CustomFormProps, PrintBadgeUserControlState> {
  private payload: PrintBadgeUserControlState | null;
  constructor(props: any) {
    super(props);

    const tokenPayload = this.props.userTask?.tokens.find(token => token.type === 'onEnter')?.payload;
    this.payload = {
      teilnehmer: tokenPayload.teilnehmer,
      isPrinted: tokenPayload.isPrinted,
      hasBeenChanged: tokenPayload.hasBeenChanged,
      continue: true,
    };
    this.state = this.payload as PrintBadgeUserControlState;
  }

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card2'>
          {FiveMindsHeader}
          <div className='card_content'>
            <BadgeCanvas
              teilnehmer={this.state.teilnehmer}
              finishPrint={() => this._handleFinishPrint()}
              cancelPrint={() => this._handleCancel()}
              badgeCreated={(s) => s}
            />
          </div>
        </div>
      </div>
    );
  }

  private _handleCancel(): void {
    this.props.finishUserTask({ ...this.state, continue: false });
  }

  private _handleFinishPrint(): void {
    const result: PrintBadgeUserControlState = { ...this.state, isPrinted: true, hasBeenChanged: true };
    this.props.finishUserTask(result);
  }
}
