import React from 'react';
import { StartDialogProps } from '../DialogRenderer';
import { Teilnehmer } from '../contracts/badge';

import './EinlassStartDialog.css';
import { BarcodeInput } from './BarcodeInput';
import { FiveMindsHeader, RuhrsummitFooter } from './FiveMindsHeader';

export type EinlassStartDialogResult = {
  teilnehmer:Teilnehmer
}

export class EinlassStartDialog extends React.Component<StartDialogProps> {

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card'>
          {FiveMindsHeader}
          <BarcodeInput
            config={this.props.config}
            finishBarcodeInput={(teilnehmer: Teilnehmer) => this._handleFinishBarcode(teilnehmer)}
          />
          {RuhrsummitFooter}
        </div>
      </div>
    );
  }

  private _handleFinishBarcode(teilnehmer: Teilnehmer): void {
    const result : EinlassStartDialogResult = { teilnehmer: teilnehmer };
    this.props.startProcess('Einlassmanagement_Process', result);
  }
}
