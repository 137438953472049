import React from 'react';
import { StartDialogProps } from '../DialogRenderer';
import { Teilnehmer } from '../contracts/badge';

import './EinlassStartDialog.css';
import { BarcodeInput } from './BarcodeInput';
import { FiveMindsHeader, RuhrsummitFooter } from './FiveMindsHeader';
import { EinlassStartDialogResult } from './EinlassStartDialog';


export class EinlassQuickStartDialog extends React.Component<StartDialogProps> {

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card'>
          {FiveMindsHeader}
          <BarcodeInput
            config={this.props.config}
            finishBarcodeInput={(teilnehmer: Teilnehmer) => this._handleFinishBarcode(teilnehmer)}
          />
          <div className='quickinfo'>
            <label>Quick Process</label>
          </div>
          {RuhrsummitFooter}
        </div>
      </div>
    );
  }

  private _handleFinishBarcode(teilnehmer: Teilnehmer): void {
    const result : EinlassStartDialogResult = { teilnehmer: teilnehmer };
    this.props.startProcess('Einlassmanagement_ProcessQuick', result);
  }

}
