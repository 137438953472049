import logo5minds from '../img/fivemindslogo.png';
import logoRuhrSummit from '../img/logoruhrsummit.png';

export const FiveMindsHeader = (
  <div className='images'>
    <img src={logo5minds} alt='5Minds' className='imageMain' />
    <h1>Einlass Management</h1>
  </div>
);

export const RuhrsummitFooter = (
  <div className='images'>
    <img src={logoRuhrSummit} alt='RuhrSummit' className='imageSub' />
  </div>
);
