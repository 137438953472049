import React from 'react';

import './PrintBadgeUserControl.css';

import { CustomFormProps } from '../DialogRenderer';

import { BadgeCanvas, PrintBadge } from './BadgeCanvas';
import { PrintBadgeUserControlState } from './PrintBadgeUserControlState';
import { FiveMindsHeader } from '../EinlassStartDialog/FiveMindsHeader';

export class QuickPrintBadgeUserControl extends React.Component<CustomFormProps, PrintBadgeUserControlState> {
  private payload: PrintBadgeUserControlState | null;

  constructor(props: any) {
    super(props);

    const tokenPayload = this.props.userTask?.tokens.find(token => token.type === 'onEnter')?.payload;
    this.payload = {
      teilnehmer: tokenPayload.teilnehmer,
      isPrinted: tokenPayload.isPrinted,
      hasBeenChanged: tokenPayload.hasBeenChanged,
      continue: true,
    };

    this.state = this.payload as PrintBadgeUserControlState;
  }

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card2'>
          {FiveMindsHeader}
          <div className='card_content'>
            <BadgeCanvas
              teilnehmer={this.state.teilnehmer}
              finishPrint={() => {} }
              cancelPrint={() => {} }
              badgeCreated={(s) => this._handlePrintBadge(s)}
            />
          </div>
        </div>
      </div>
    );
  }

  private _handlePrintBadge(dataURL: string): void {
    PrintBadge(dataURL);
    const result: PrintBadgeUserControlState = { ...this.state, isPrinted: true, hasBeenChanged: true };

    this.props.finishUserTask(result);
  }

}
