import React from 'react';

export function PrintedBadgeWarning(): JSX.Element {
  return (
    <div className='alert_banner red_alert'>
      <h4>Warnung: Badge ist schon gedruckt worden</h4>
    </div>
  );
}

export function NotAttendingWarning(): JSX.Element {
  return (
    <div className='alert_banner blue_alert'>
      <h4>Warnung: der Teilnehmer ist nicht gelistet </h4>
    </div>
  );
}
