import React from 'react';

import { CustomFormProps } from '../DialogRenderer';
import { ShowTeilnehmerDataUserControlState } from './ShowTeilnehmerDataUserControlState';
import { FiveMindsHeader, RuhrsummitFooter } from '../EinlassStartDialog/FiveMindsHeader';
import { PrintedBadgeWarning } from './PrintedBadgeWarning';

export class ShowWarningUserControl extends React.Component<CustomFormProps, ShowTeilnehmerDataUserControlState> {

  private payload: ShowTeilnehmerDataUserControlState | null;

  constructor(props: any) {
    super(props);

    const tokenPayload = this.props.userTask?.tokens.find(token => token.type === 'onEnter')?.payload;

    this.payload = {
      teilnehmer: tokenPayload.teilnehmer,
      isPrinted: tokenPayload.isPrinted,
      hasBeenChanged: false,
      continue: true,
    };

    this.state = this.payload;
  }

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card'>
          {FiveMindsHeader}
          {this.state.isPrinted ? PrintedBadgeWarning() : null}
          <form className='buttons' onSubmit={() => this._handleSubmit()}>
            <button autoFocus={true}>Get Next QrCode</button>
          </form>
          {RuhrsummitFooter}
        </div>
      </div>
    );
  }

  private _handleSubmit(): void {
    this.props.finishUserTask({});
  }
}
