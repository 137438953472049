import React, { FormEvent } from 'react';

import {
  Teilnehmer,
  BadgeEntry,
  EventbriteTeilnehmer,
  EventbriteError,
} from '../contracts/badge';
import { Config } from '../config//Config';

import { HttpService } from '../Services/HttpService';

type BarcodeInputProps = {
  finishBarcodeInput: (teilnehmer: Teilnehmer) => void;
  config: Config | undefined;
};

type BarcodeInputState = {
  qrCodeId: string;
  teilnehmer: Teilnehmer;
};

const minTeilnehmerNameLength = 3;
const minQrCodeLength = 10;

export class BarcodeInput extends React.Component<BarcodeInputProps, BarcodeInputState> {
  private httpService = new HttpService();

  constructor(props: BarcodeInputProps) {
    super(props);
    this.state = {
      qrCodeId: '',
      teilnehmer: {
        id: '',
        barcode: '',
        name: '',
        org: '',
        entryType: BadgeEntry.None,
        status: '',
      },
    };
  }

  public render(): JSX.Element {
    return (
      <form id='enterBarcodeForm' onSubmit={(event) => this.handleBarcodeSubmit(event)}>
        <div className='input-text'>
          <h2>QR-Code Scannen:</h2>
          <input
            type='text'
            value={this.state.qrCodeId}
            onChange={(e) => this.handleBarcodeChange(e)}
            autoFocus={true}
          />
        </div>
      </form>
    );
  }

  private handleBarcodeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ qrCodeId: event.currentTarget.value });
  }

  private async getTeilnehmerData(qrcode: string): Promise<Teilnehmer> {
    let deleteTeilnehmer = false;
    if (qrcode.startsWith('del')) {
      deleteTeilnehmer = true;
      qrcode = qrcode.replace('del', '');
    }

    // Berechnung der TeilnehmerID aus QR-Code:
    // QR-CODE Beispiel: 963193635915914773059001
    // => 9631936359 15914773059 001 = OrderId(oid) TeilnehmerId(aid) 001
    // Achtung!! Länge der oid und aid kann sich ändern!!
    const teilnehmerIdLength = 11;
    const teilnehmerId = qrcode.substring((qrcode.length - teilnehmerIdLength - 3), qrcode.length - 3);

    const result = await this.requestTeilnehmerData(teilnehmerId);

    let teilnehmer: Teilnehmer | undefined = undefined;

    try {
      teilnehmer = {
        id: teilnehmerId,
        barcode: qrcode,
        name: `${result.profile.first_name} ${result.profile.last_name}`,
        entryType: result.ticket_class_name,
        org: result.profile.company ?? '',
        status: result.status,
      };

      if (teilnehmer.status === 'Attending' && deleteTeilnehmer) {
        teilnehmer.status = 'Delete';
      }
    } catch (error) {
      teilnehmer = {
        id: '',
        barcode: '',
        name: '',
        entryType: BadgeEntry.None,
        org: '',
        status: 'Error',
      };
    }

    return teilnehmer;
  }

  private async requestTeilnehmerData(attendantId: string): Promise<EventbriteTeilnehmer> {
    const config = this.props.config;
    const apiKey = config?.eventbrite.apiKey ?? '';
    const eventId = config?.eventbrite.eventId ?? '';

    let result = await this.httpService.getAsync<EventbriteTeilnehmer>(
      apiKey,
      `https://www.eventbriteapi.com/v3/events/${eventId}/attendees/${attendantId}/`,
    );

    if ((result?.status ?? '') === '') {
      result = await this.getErrorFromEventbrite(apiKey, eventId, attendantId);
    }

    return result;
  }

  private async getErrorFromEventbrite(
    apiKey: string,
    eventId: string,
    attendantId: string,
  ): Promise<EventbriteTeilnehmer> {
    const error = await this.httpService.getAsync<EventbriteError>(
      apiKey,
      `https://www.eventbriteapi.com/v3/events/${eventId}/attendees/${attendantId}/`,
    );

    const result = {
      profile: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        first_name: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        last_name: '',
        prefix: '',
        company: '',
        name: '',
      },
      status: `EventBriteError: ${error.error_description}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      answers: [{ answer: '', question: '', question_id: '', type: '' }],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ticket_class_name: BadgeEntry.None,
    };

    return result;
  }

  private async handleBarcodeSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    const qrcode = this.state.qrCodeId;
    if (qrcode.length < minQrCodeLength) {
      this.setState({ qrCodeId: '' });
      return;
    }
    const teilnehmer = await this.getTeilnehmerData(qrcode);

    if (teilnehmer.name.length > minTeilnehmerNameLength) {
      this.setState((prev) => ({
        ...prev,
        teilnehmer: teilnehmer,
      }));
    }

    this.props.finishBarcodeInput(teilnehmer);
  }
}
