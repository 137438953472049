import React from 'react';
import printJS from 'print-js';

import { Teilnehmer } from '../contracts/badge';

import badge_underlay from '../img/Badge_underlay_400.png';

import badge_2024 from '../img/Badge_RS2024_400.png';

export type CanvasProps = {
  teilnehmer: Teilnehmer;
  finishPrint: () => void;
  cancelPrint: () => void;
  badgeCreated: (badgeData: string) => void;
};

export class BadgeCanvas extends React.Component<CanvasProps> {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  printCanvasRef: React.RefObject<HTMLCanvasElement>;
  escape: boolean;
  constructor(props:CanvasProps) {
    super(props);
    this.canvasRef = React.createRef();
    this.printCanvasRef = React.createRef();
    this.escape = false;
  }

  componentDidMount(): void {
    this.draw();
  }

  componentDidUpdate(): void {
    this.draw();
  }

  draw(): void {
    const teilnehmer = this.props.teilnehmer;
    const canvas = this.canvasRef.current;
    const printCanvas = this.printCanvasRef.current;

    if (canvas === null || printCanvas === null) {
      return;
    }

    const context = canvas.getContext('2d');
    const printContext = printCanvas.getContext('2d');
    if (context === null || printContext === null) {
      return;
    }

    const { demoBadge, badge } = this.GetBadges(teilnehmer);

    const demoImage = new Image();
    demoImage.onload = () => {
      context.drawImage(demoImage, 0, 0);
      context.font = '600 26px Kanit';
      context.fillStyle = 'black';
      // Empty fillText: needed to correctly initialize Font
      context.fillText(' ', 0, 0);
      context.font = '600 26px Kanit';
      context.fillText(`${teilnehmer.name}`, 50, 260, 340);
      context.font = '400 26px Kanit';
      context.fillText(`${teilnehmer.org}`, 50, 290, 300);
      context.fillStyle = 'grey';
      context.font = '200 8px Kanit';
      context.fillText(`${teilnehmer.entryType}`, 50, 320, 300);
      /* context.beginPath();
      context.arc(84, 392, 35, 0, 2 * Math.PI);
      context.fillStyle = 'black';
      context.fill(); */
    };

    demoImage.src = demoBadge;

    const printImage = new Image();
    printImage.onload = () => {
      printContext.clearRect(0, 0, printCanvas.width, printCanvas.height);

      printContext.font = '600 80px Kanit';
      printContext.fillStyle = 'black';
      printContext.fillText(`${teilnehmer.name}`, 40, 100, 980);
      printContext.font = '400 80px Kanit';
      printContext.fillText(`${teilnehmer.org}`, 40, 180, 950);

      printContext.fillStyle = 'grey';
      printContext.font = '200 30px Kanit';
      printContext.fillText(`${teilnehmer.entryType}`, 40, 300, 300);

      const badgeData = printCanvas.toDataURL();
      this.props.badgeCreated(badgeData);
    };

    printImage.src = badge;
  }

  private GetBadges(teilnehmer: Teilnehmer):{demoBadge:string, badge:string} {
    let badge = badge_underlay;
    let demoBadge = badge_underlay;
    badge = badge_2024;
    demoBadge = badge_2024;

    return { demoBadge, badge };
  }

  public render():JSX.Element {
    return (
      <div>
        <canvas className='demoCanvas' ref={this.canvasRef} width={400} height={563} />;
        <canvas ref={this.printCanvasRef} width={1051} height={331} style={{ display: 'none' }} />
        <div className='buttons'>
          <button style={{ backgroundColor: 'firebrick', width: '60%' }} onClick={event => this.props.cancelPrint()}>Cancel</button>
          <button
            onClick={event => this.handlePrint()}
            autoFocus={true}
            onKeyDown={event => this.handleKeyDown(event)}
          >Print
          </button>
        </div>
      </div>
    );
  }

  private handleKeyDown(event: React.KeyboardEvent<HTMLButtonElement>): void {
    if (event.key === 'Escape') {
      this.props.cancelPrint();
      this.escape = true;
    }
  }

  handlePrint(): void {
    if (this.escape) {
      return;
    }
    const dataURL = this.printCanvasRef.current?.toDataURL();
    PrintBadge(dataURL);
    this.props.finishPrint();
  }
}

export function PrintBadge(dataURL: string | undefined):void {
  printJS({
    printable: dataURL,
    type: 'image',
    style: '@media print {body {margin:0px;} img {height:100vh;display:block;}}',
  });
}
