import { ToastrService } from './ToastrService';

export class HttpService {
  private toastrService = new ToastrService();

  public async getAsync<T>(identityToken: string, endpointUri: string): Promise<T> {
    const getResult = await this.fetchAsync<T, null>('GET', identityToken, endpointUri);

    return getResult;
  }

  public async putAsync<T, TB>(identityToken: string, endpointUri: string, body: TB): Promise<T> {
    const getResult = await this.fetchAsync<T, TB>('PUT', identityToken, endpointUri, body);

    return getResult;
  }

  public async postAsync<T, TB>(identityToken: string, endpointUri: string, body: TB): Promise<T> {
    const getResult = await this.fetchAsync<T, TB>('POST', identityToken, endpointUri, body);

    return getResult;
  }

  public async deleteAsync<T>(identityToken: string, endpointUri: string): Promise<T> {
    const getResult = await this.fetchAsync<T, null>('DELETE', identityToken, endpointUri);

    return getResult;
  }

  private createRequestInit<TB>(method: string, identityToken: string, body?: TB): RequestInit {
    const headers: HeadersInit = {
      Accept: 'application/json',
      Authorization: `Bearer ${identityToken}`,
    };

    if (body) {
      headers['Content-Type'] = 'application/json';
    }

    const requestInit: RequestInit = {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : undefined,
    };

    return requestInit;
  }

  private async fetchAsync<T, TB>(method: string, identityToken: string, endpointUri: string, body?: TB): Promise<T> {
    const requestInit = this.createRequestInit(method, identityToken, body);
    const response = await fetch(endpointUri, requestInit);
    if (!response.ok) {
      this.toastrService.showError();
    }
    const result = response.json();

    return result;
  }
}
