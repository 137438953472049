import React from 'react';

import { CustomFormProps } from '../DialogRenderer';
import { FiveMindsHeader, RuhrsummitFooter } from '../EinlassStartDialog/FiveMindsHeader';
import { NotAttendingWarning } from './PrintedBadgeWarning';
import { EinlassStartDialogResult } from '../EinlassStartDialog';

export class NotAttendingUserControl extends React.Component<CustomFormProps, EinlassStartDialogResult> {

  private payload: EinlassStartDialogResult | null;

  constructor(props: any) {
    super(props);

    const tokenPayload = this.props.userTask?.tokens.find(token => token.type === 'onEnter')?.payload;

    this.payload = {
      teilnehmer: tokenPayload.teilnehmer,
    };

    this.state = this.payload as EinlassStartDialogResult;
  }

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card'>
          {FiveMindsHeader}
          {this.state.teilnehmer.status !== 'Attending' ? NotAttendingWarning() : null}
          <form className='buttons' onSubmit={() => this._handleSubmit()}>
            <button autoFocus={true}>Get Next QrCode</button>
          </form>
          <div className='images'>
            <label>Status: &quot;{this.state.teilnehmer.status}&quot;</label>
          </div>
          {RuhrsummitFooter}
        </div>
      </div>
    );
  }

  private _handleSubmit(): void {
    this.props.finishUserTask({});
  }
}
