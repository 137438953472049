import React from 'react';
import { CustomFormProps } from '../DialogRenderer';

import '../EinlassStartDialog/EinlassStartDialog.css';
import logo5minds from '../img/fivemindslogo.png';
import logoRuhrSummit from '../img/logoruhrsummit.png';

import { PrintedBadgeWarning } from './PrintedBadgeWarning';
import { ShowTeilnehmerDataUserControlState } from './ShowTeilnehmerDataUserControlState';

export class ShowTeilnehmerDataUserControl extends React.Component<CustomFormProps, ShowTeilnehmerDataUserControlState> {
  private payload: ShowTeilnehmerDataUserControlState | null;
  constructor(props: any) {
    super(props);

    const tokenPayload = this.props.userTask?.tokens.find(token => token.type === 'onEnter')?.payload;

    this.payload = {
      teilnehmer: tokenPayload.teilnehmer,
      isPrinted: tokenPayload.isPrinted,
      hasBeenChanged: false,
      continue: true,
    };
    this.state = this.payload as ShowTeilnehmerDataUserControlState;
  }

  public render(): JSX.Element {
    return (
      <div className='container'>
        <div className='card'>
          <div className='images'>
            <img src={logo5minds} alt='5Minds' className='imageMain' />
            <h1>Einlass Management</h1>
          </div>
          {this.showUserData()}
          <div className='images'>
            <img src={logoRuhrSummit} alt='RuhrSummit' className='imageSub' />
          </div>
        </div>
      </div>
    );
  }

  private showUserData():JSX.Element {
    return (
      <form id='showDataForm'>
        <div className='userdata'>
          <label htmlFor='username'>Username:</label>
          <input
            name='username'
            type='text'
            value={this.state.teilnehmer.name}
            autoFocus={true}
            onChange={item => this.handleChangeName(item.currentTarget.value)}
            onKeyDown={event => this.handleKeyPress(event)}
          />
        </div>
        <div className='userdata'>
          <label htmlFor='organisation'>Organisation:</label>
          <input
            name='organisation'
            type='text'
            value={this.state.teilnehmer.org}
            onChange={item => this.handleChangeOrg(item.currentTarget.value)}
            onKeyDown={event => this.handleKeyPress(event)}
          />
        </div>
        {this.state.isPrinted ? PrintedBadgeWarning() : null}
        <div className='buttons'>
          <button style={{ backgroundColor: 'firebrick', width: '60%' }} onClick={() => this.handleCancel()}>Return</button>
          <button
            type='submit'
            onClick={event => this.handleSubmitData(event)}
            onKeyDown={event => this.handleKeyPress(event)}
          >Submit
          </button>
        </div>
      </form>
    );
  }

  private handleKeyPress(event: React.KeyboardEvent<HTMLElement>): void {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.handleCancel();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      this.props.finishUserTask({ ...this.state, continue: true });
    }
  }

  private handleCancel(): void {
    this.setState({
      ...this.state,
      continue: false,
    });
    this.props.finishUserTask({ ...this.state, continue: false });
  }

  private handleSubmitData(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.finishUserTask(this.state);
  }

  private handleChangeOrg(_org: string): void {
    const hasBeenChanged = this.state.hasBeenChanged || _org !== this.state.teilnehmer.org;
    this.setState((prev) => ({
      ...prev,
      teilnehmer: {
        ...prev.teilnehmer,
        org: _org,
      },
      hasBeenChanged: hasBeenChanged,
    }));
  }

  private handleChangeName(_name: string): void {
    const hasBeenChanged = this.state.hasBeenChanged || _name !== this.state.teilnehmer.name;

    this.setState((prev) => ({
      ...prev,
      teilnehmer: {
        ...prev.teilnehmer,
        name: _name,
      },
      hasBeenChanged: hasBeenChanged,
    }));
  }
}
