import toastr from 'toastr';

toastr.options.positionClass = 'toast-bottom-right';
toastr.options.preventDuplicates = true;
toastr.options.timeOut = 0;
toastr.options.closeButton = true;

export class ToastrService {
  public showError(): void {
    toastr.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
  }
}
