export enum BadgeEntry {
  None= 'None',
  Corporate = 'Corporate',
  FutureFounder = 'FutureFounder',
  Investor = 'Investor',
  Speaker = 'Speaker',
  StartUp = 'Startup',
  Team = 'Team',
  Presseticket = 'Presseticket',
  StudierendenTicket = 'StudierendenTicket'
}
